<template>
  <div class="intro" :class="no_padding">
    <div class="content">
      <span class="f-34 sup-ttl">{{ $get(contentData, 'data.list.0.title2') }}</span>
      <h2 class=" title">
        {{ $get(contentData, 'data.list.0.title') }}
      </h2>
      <p class="f-34 teaser">
        {{ $get(contentData, 'data.list.0.teaser2') }}

        <router-link class="link" :to="$get(contentData, 'data.list.0.link.url')||''"
                     :target="$get(contentData, 'data.list.0.link.url_target') === '1' ? '_blank' : 'self'">
          {{ $get(contentData, 'data.list.0.link.title') }}
        </router-link>
      </p>
    </div>
    <Explore/>
  </div>
</template>
<script>
import { computed } from 'vue';
import Explore from '../../UI/Explore';

export default {
  name: 'Main_Intro',
  props: {
    contentData: {}
  },
  components: {
    Explore
  },
  setup(props){
    const no_padding = computed(() => {
      return props.contentData.conf.conf?.includes('no_padding') ? 'no-padding' : '';
    })

    return {no_padding}
  }
}
</script>

<style lang="scss" scoped>
.intro {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.intro.no-padding{
  padding-top: 0 !important;
}

.content {
  width: 960px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  text-transform: uppercase;
  margin-top: 89px;
  font-size: 55px!important;
  line-height: 70px!important;
  @media (max-width: 1280px) {
    font-size: 34px!important;
    line-height: 51px!important;
  }
}

.teaser,
.sup-ttl {
  font-size: 34px;
  line-height: 41px;
}

.teaser {
  margin-top: 89px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.link {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  margin-left: 5px;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: translateX(-100%);
    transition: .8s cubic-bezier(0.77, 0, 0.175, 1);
    background: #000;
  }

  &:hover:after {
    transform: translateX(0%);
  }
}


@media screen and (max-width: 1900px) {


  .content {
    max-width: 960px;
  }

  .title {
    font-size: 64px;
    line-height: 77px;
    margin-top: 60px;
  }

  .teaser,
  .sup-ttl {
    font-size: 28px;
    line-height: 34px;
  }

  .teaser {
    margin-top: 60px;
  }

  .explore {
    right: 21px;
    bottom: 51px;
  }

}

@media screen and (max-width: 1439px) {
  .intro {
    padding-top: 91px;
  }

  .content {
    max-width: 853px;
  }

  .title {
    font-size: 64px;
    line-height: 77px;
    margin-top: 53px;
  }

  .teaser,
  .sup-ttl {
    font-size: 28px;
    line-height: 34px;
  }

  .teaser {
    margin-top: 71px;
  }

}

@media screen and (max-width: 1279px) {
  .intro {
    padding-top: 54px;
  }

  .content {
    max-width: 512px;
  }

  .title {
    font-size: 34px;
    line-height: 41px;
    margin-top: 32px;
  }

  .teaser,
  .sup-ttl {
    font-size: 16px;
    line-height: 19px;
  }

  .link {
    padding-bottom: 3px;

    &:after {
      height: 1px;
    }
  }

  .teaser {
    margin-top: 32px;
  }

}

@media screen and (max-width: 767px) {
  .intro {
    padding: 24px 16px 0;
    display: flex;
    flex-direction: column;
  }

  .content {
    width: 100%;
    max-width: 100%;
    text-align: start;
  }

  .title {
    font-size: 28px;
    line-height: 34px;
    margin-top: 36px;
  }

  .teaser,
  .sup-ttl {
    font-size: 24px;
    line-height: 28px;
  }

  .teaser {
    margin-top: 26px;
    flex-direction: column;
    align-items: flex-start;

    .link {
      margin-top: 16px;
      margin-left: 0;

      &:after {
        transform: translateX(0%);
      }
    }
  }

}
</style>
