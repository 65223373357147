<template>
  <router-link
      :to="'/' + store.indx.locale + getWidget.link.url"
      class="explore__link"
      :class="[scrolled ? 'active':'']"
      v-if="getWidget"
      :target="getWidget.link.targetblank ? '_blank' : ''"
  >
        <div class="explore__link-top">
          <svg width="176" height="184" viewBox="0 0 176 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M157.534 87.8786L0.91211 87.8786L0.912109 97.8786L155.777 97.8786L77.649 176.006L84.7201 183.078L172.262 95.5355L175.798 92L172.262 88.4645L84.7201 0.922488L77.649 7.99356L157.534 87.8786Z" fill="black"/>
          </svg>
        </div>
      <div class="explore__link-bottom">
        <img :src="$get(getWidget,'image.[0].devices.desktop')" alt="video" width="170" height="170"/>
        <div class="explore__link-txt">
          <span>{{getWidget.title}}</span>
        </div>
      </div>
    </router-link>
</template>
<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useMenuStore} from '@/store/pinia/menu';
import {get} from "lodash";
const store = useMenuStore();
let scrolled = ref(true);

// Define reactive data
const getWidget = computed(() => {
  return get(store, 'indx.widgets.explore-cityzen');
}); 
const headerBehaviour = () => {
  if (window.innerWidth < 1024) {
    document.querySelector('#app').addEventListener('scroll', e => {
      scrolled.value = e.target.scrollTop < 200
    })
    return
  }
  setTimeout(() => {
    store.Loco?.on('scroll', func => {
      scrolled.value = func.scroll.y <  300
    });
  }, 1000)
}
onMounted(() => {
  headerBehaviour()
})

</script>
<style lang="scss" scoped>
  .explore__link {
    position: absolute;
    right: 20px;
    bottom: -23px;
  }
  .explore__link-bottom {
    width: 140px;
    height: 140px;
    position: relative;
    display: flex;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    opacity: 0;
  }
  .explore__link.active .explore__link-bottom {
    opacity: 1;
  }
  .explore__link-bottom img {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    object-fit: cover;
    z-index: 1;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .explore__link-bottom:after {
    z-index: 2;
    content: '';
    background: rgba(0,0,0,.5);
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .explore__link:hover .explore__link-bottom img {
    transform: scale(1.05)
  }
  .explore__link:hover .explore__link-bottom:after {
    z-index: 2;
    content: '';
    background: rgba(0,0,0,.4);
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
  }
  .explore__link-txt {
    position: relative;
    z-index: 4;
    font-size: 21px;
    line-height: 25px;
    color: #fff;
    text-transform: uppercase;
    margin-left: 7px;
    margin-top: auto;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: translateY(10px);
    opacity: 0;
    transition-delay: 0s;
  }
  .explore__link.active .explore__link-txt {
    transform: translateY(0px);
    opacity: 1;
    transition-delay: .1s;
  }
  .explore__link-txt span {
    font-size: inherit;
    line-height: inherit;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .explore__link:hover .explore__link-txt span{
    opacity: .9;
  }
  .explore__link-top {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: translateX(-5px);
    opacity: 0;
  }
  .explore__link.active .explore__link-top{
    transform: translateX(0px);
    opacity: 1;
  }
  .explore__link-top svg {
    transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    width: 78px;
    height: 78px;
    @media only screen and (max-width: 1280px) and (min-width: 768px) {
      width: 62px;
      height: 62px;
    }
    @media only screen and (max-width: 767px) {
      height: 120px;
      width: 120px;
      margin-left: 20px;
    }
  }
  .explore__link:hover .explore__link-top svg{
    transform: translateX(10px);

  }

  //Laptop
  @media (max-width: 1900px) {
    .explore__link-bottom {
      position: relative;
      display: flex;
      overflow: hidden;
    }
    .explore__link-txt {
      font-size: 21px;
    }
  }
  //Ipad
  @media (max-width: 1279px) {
    .explore__link-bottom {
      height: 96px;
      width: 96px;
    }
    .explore__link-txt {
      font-size: 16px;
      line-height: 21px;
      margin-left: 5px;
    }
    .explore__link-top {
      margin-bottom: 17px;
    }
    .explore__link {
      bottom: 20px;
      right: 10px;
    }
  }
  //Mobile
  @media (max-width: 767px) {
    .explore__link {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      bottom: auto;
      right: auto;
      margin-top: 50px;
      align-items: center;
      margin-bottom: 5px;
    }
    .explore__link-bottom {
      width: 140px;
      height: 140px;
    }
    .explore__link-txt {
      font-size: 16px;
      line-height: 21px;
      margin-left: 7px;
    }
    .explore__link-top {
      margin-bottom: 0px;
    }
  }
</style>
